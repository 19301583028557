<template>
    <div class="mainContainer">
        <div style="display: flex; flex-wrap: wrap; gap: 15px; justify-content: center; align-items: center;">
            <div v-for="(item, index) in tokenInfo" :class="calculateClass(item)">
                {{ index + 1 }}
            </div>
        </div>
    </div>
    </template>
    
    <script>
    
    
    
    export default {
        name: 'allotedOPDSlotView',
        props: ['tokenInfo'],
        data: function() {
            return {
                todayAppointment: [],
                pageLoading: true,
                ongoingAppointment: [],
                bannerURL: '',
                viewDetailsModel: false,
                hospitalName: 'Asia Royal Hospital',
                hospitalLocation: 'Yangon',
                hospitalRegNumber: '1241424',
                phoneNumber: '+95 67980342453',
                reviews: '299',
                doctorCount: 56,
                fullDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
                readMore: false,
                newTokenInfo: [],
            };
        },
        computed: {
            textPreview() {
                return this.fullDescription.slice(0, 100) + '...';
            }
        },
        async mounted() {
            this.newTokenInfo = this.tokenInfo;
        },
        methods: {
            calculateClass(item) {
                switch(item.patientStatus) {
                    case 'NOT_REACHED':
                        return 'dateBox';
                    case 'ARRIVED':
                        return 'dateBox arrivedBox';
                    case 'COMPLETED':
                        return 'dateBox completedBox';
                    case 'SEEING_DOCTOR':
                        return 'dateBox seeingBox'
                    default: 
                        return 'dateBox'
                    }

            },
            goToOPDPage() {
                this.$router.push({
                    name: 'OPDHome'
                })
            },
            toggleHospitalDetails() {
                this.viewDetailsModel = !this.viewDetailsModel;
            },
            closeBottomSheet() {
                this.viewDetailsModel = false;
            },
            descriptionPreview() {
    
            }
        },
    };
    
    </script>
    <style  scoped>
    .mainContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        background: #f7f8fb;
        border: 1px solid #e0e0e0;
        padding: 24px 20px 24px 20px;
    }
    .dateBox {
        width: 32px;
        height: 32px;
        background: red;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #e0e0e0;
        background: white;
    }
    .arrivedBox {
        background: #1467bf !important;
        color: white !important;
        border: 1px solid #1467bf;
    }
    .seeingBox {
        background: #be5b13 !important;
        color: white !important;
        border: 1px solid #be5b13;
    }
    .completedBox {
        background: #01792a !important;
        color: white !important;
        border: 1px solid #01792a;
    }
    .doctorName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }
    .doctorProfile {
        border-radius: 50%;
        max-height: 56px;
        max-width: 56px;
        height: 56px;
        width: 56px;
        border: 1px solid #e0e0e0;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .doctorNameAndLogo {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-grow: 1;
    }
    .specialtyBox {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E4F5FF;
        padding: 3px 8px 3px 8px;
    }
    .hospitalName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .hospitalNameAndLogo {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        font-size: 14px !important;
    }
    .infoBox {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e0e0e0;
        padding: 0 0 8px 0;
        gap: 10px;
        align-items: flex-start;
    }
    .nameBox {
        font-size: 14px;
        color: #828282;
        font-weight: 400;
    }
    .valueBox {
        font-size: 14px;
        color: #333333;
        font-weight: 500;   
    }
    </style>
        